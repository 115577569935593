<template>
    <v-col class="StationsOverview full-height content-area pa-2">
        <v-row class="full-height">

<!--            Station 1-->
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}" @click="goToStation(1)"
                   v-if="stations[0].connected === 'connected'">
                    <station-single-overview stationId="1"
                                             :twoStations="stationsCount < 3"
                                             error-message=""
                                             :info-icon="showInfoIcon(1)"
                                             info-message=""/>
            </v-col>
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}"
                   v-if="stations[0].connected === 'disconnected'">
                <station-single-overview-disconnected stationId="1"
                                                      :twoStations="stationsCount < 3"
                                                      />
            </v-col>
            <v-col v-if="(stations[0].connected === 'noStation' && stationsCount > 2) || stations[0].connected === ''"
                   cols="6" class="station pa-2">
                <div class="placeholder"></div>
            </v-col>

<!--            Station 2-->
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}" @click="goToStation(2)"
                   v-if="stations[1].connected === 'connected'">
                    <station-single-overview stationId="2"
                                             :twoStations="stationsCount < 3"
                                             error-message=""
                                             :info-icon="showInfoIcon(2)"
                                             info-message=""/>
            </v-col>
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}"
                   v-if="stations[1].connected === 'disconnected'">
                <station-single-overview-disconnected stationId="2" :twoStations="stationsCount < 3" error-message=""
                                                      info-message=""/>
            </v-col>
            <v-col v-if="(stations[1].connected === 'noStation' && stationsCount > 2) || stations[1].connected === ''"
                   cols="6" class="station pa-2">
                <div class="placeholder"></div>
            </v-col>

<!--            Station 3-->
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}" @click="goToStation(3)"
                   v-if="stations[2].connected === 'connected'">
                    <station-single-overview stationId="3"
                                             :twoStations="stationsCount < 3"
                                             error-message=""
                                             :info-icon="showInfoIcon(3)"
                                             info-message=""/>
            </v-col>
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}"
                   v-if="stations[2].connected === 'disconnected'">
                <station-single-overview-disconnected stationId="3" :twoStations="stationsCount < 3" error-message=""
                                                      info-message=""/>
            </v-col>
            <v-col v-if="(stations[2].connected === 'noStation' && stationsCount > 2) || stations[2].connected === ''"
                   cols="6" class="station pa-2">
                <div class="placeholder"></div>
            </v-col>

<!--            Station 4-->
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}" @click="goToStation(4)"
                   v-if="stations[3].connected === 'connected'">
                    <station-single-overview stationId="4"
                                             :twoStations="stationsCount < 3"
                                             error-message=""
                                             :info-icon="showInfoIcon(4)"
                                             info-message=""/>
            </v-col>
            <v-col cols="6" class="station pa-2" :class="{'twoStations': stationsCount < 3}"
                   v-if="stations[3].connected === 'disconnected'">
                <station-single-overview-disconnected stationId="4" :twoStations="stationsCount < 3" error-message=""
                                                      info-message=""/>
            </v-col>
            <v-col v-if="(stations[3].connected === 'noStation' && stationsCount > 2) || stations[3].connected === ''"
                   cols="6" class="station pa-2">
                <div class="placeholder"></div>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import StationSingleOverview from '@/components/StationSingleOverview.vue'
import StationSingleOverviewDisconnected from '@/components/StationSingleOverviewDisconnected.vue'
import {mapMutations, mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'StationsOverview',
    props: {},
    components: {
        StationSingleOverview,
        StationSingleOverviewDisconnected
    },
    data() {
        return {}
    },
    computed: {
        ...mapState([
            'stations',
            'errorScreen',
        ]),
        stationsCount() {
            let activeStations = [];
            this.stations.forEach(station => {
                if (station.connected !== 'noStation' && station.connected !== '') {
                    activeStations.push(station);
                }
            })
            return activeStations.length
        },
    },
    methods: {
        ...mapMutations([
            'changeStation',
        ]),
        getStationStatus() {
            this.$store.dispatch('getAxiosSetter', ['/disi/station/status', 'stations'])
                .then(response => {
                    if (response.status === 200) {
                        if (response.data[0].connected && this.$router.currentRoute.name === 'StationsOverview') {
                            setTimeout(() => {
                                this.getStationStatus();
                            }, 500)
                        }
                    }
                })
        },
        showInfoIcon(stationNo) {
            if (this.stations[stationNo - 1].runningTest.status.status === 5 || this.stations[stationNo - 1].runningTest.status.status === 6 || this.stations[stationNo - 1].runningTest.status.status === 14) {
                return true;
            } else {
                return false;
            }
        },
        goToStation(StationNo) {
            if (this.checkUserRights('testMonitor')) {
                this.changeStation(StationNo);
                this.$router.push('/station-testdetail');
            } else {
                this.$router.push('/login');
            }
        },
    },
    mixins: [mixins],
    created() {
        this.getStationStatus();
        this.$watch('$store.state.errorScreen.status', function () {
            if (this.$store.state.errorScreen.status == false) {
                this.getStationStatus();
            }
        });
    },
    //check if more than 1 station is connected
    beforeRouteEnter (to, from, next) {
        next(vm => {
            let activeStations = [];
            let activeStationNo = null;
            vm.stations.forEach(function (station, i) {
                if (station.connected !== 'noStation' && station.connected !== '') {
                    activeStations.push(station);
                    activeStationNo = i + 1;
                }
            });
            if (activeStations.length < 2) {
                vm.goToStation(activeStationNo);
            } else {
                next();
            }
        })
    },
}
</script>
<style scoped lang="scss">
.StationsOverview {
    overflow: hidden;
}

.station {
    max-height: 50%;
}

.twoStations.station {
    max-height: 100%;
}

.placeholder {
    width: 100%;
    height: 100%;
    background: $disiBluegreyMedium;
}
</style>