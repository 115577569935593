<template>
    <div class="StationSingleOverview bg-white full-height d-flex flex-column">
        <div class="title-bar d-flex pa-4 align-center">
            <p class="stationNumber mr-4">{{ stationId }}</p>
            <v-spacer/>
            <p v-if="stations[stationId - 1].connected === 'disconnected'"
               class=" ml-8 d-flex align-center">
                {{ $t('overview.stationDisconnected') }}
            </p>
        </div>
        <v-row class="overview-area flex-grow-1 pa-4 pt-0 pb-0">
            <v-col cols="5" class="d-flex flex-column basket">
                <div class="detail_basket flex-grow-1">
                    <div class="tubes">
                        <div class="detail_basketContainer--top d-flex justify-space-between align-end">
                            <div class="tube_info disconnected">
                                6
                            </div>
                            <div class="tube_info align-self-start disconnected">
                                1
                            </div>
                            <div class="tube_info disconnected">
                                2
                            </div>
                        </div>
                        <div class="detail_basketContainer--bottom d-flex justify-space-between mt-4">
                            <div class="tube_info disconnected">
                                5
                            </div>
                            <div class="tube_info align-self-end disconnected">
                                4
                            </div>
                            <div class="tube_info disconnected">
                                3
                            </div>
                        </div>

                        <div class="detail_basketContainer--top d-flex justify-space-between align-end">
                            <v-progress-circular :size="checkSizeCircle" :width="3" :rotate="0" :value="0"
                                                 color="disiIrisblue" class=""></v-progress-circular>
                            <v-progress-circular :size="checkSizeCircle" :width="3" :rotate="0" :value="0"
                                                 color="disiIrisblue" class="align-self-start"></v-progress-circular>
                            <v-progress-circular :size="checkSizeCircle" :width="3" :rotate="0" :value="0"
                                                 color="disiIrisblue" class=""></v-progress-circular>
                        </div>

                        <div class="detail_basketContainer--bottom d-flex justify-space-between mt-4">
                            <v-progress-circular :size="checkSizeCircle" :width="3" :rotate="0" :value="0"
                                                 color="disiIrisblue" class=""></v-progress-circular>
                            <v-progress-circular :size="checkSizeCircle" :width="3" :rotate="0" :value="0"
                                                 color="disiIrisblue" class="align-self-end"></v-progress-circular>
                            <v-progress-circular :size="checkSizeCircle" :width="3" :rotate="0" :value="0"
                                                 color="disiIrisblue" class=""></v-progress-circular>
                        </div>
                    </div>
                    <v-progress-circular :size="165" :width="8" :value=0 color="disiIrisblue"
                                         class="basket_status overAll">
                    </v-progress-circular>
                </div>
            </v-col>
            <v-col cols="7" class="d-flex flex-column justify-space-between pt-4 pl-0 pb-4 pr-0 infos">

                <v-row class="pa-0 d-flex justify-end align-end infos_bottom">
                    <p class="temperature p-big-green mr-6 d-flex align-center disabled">00°</p>
                    <div class="heater-switch">
                        <p class="switchLabel text-center">{{ $t('stationDetail.heater') }}:</p>
                        <switch-on-off name="switch-disconnected" class="ml-auto disabled"/>
                    </div>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import {mapState} from "vuex";

export default {
    name: "StationSingleOverview",
    components: {
        SwitchOnOff,
    },
    props: {
        stationId: {
            type: String,
            required: true,
        },
        twoStations: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapState([
            'stations',
        ]),
        checkSizeCircle() {
            if (this.twoStations) {
                return 48;
            } else {
                return 30;
            }
        },
    },
    methods: {
    },
    created() {
    }
}
</script>

<style scoped lang="scss">

.v-application .StationSingleOverview {
    position: relative;

    .overview-area {
        position: relative;
    }

    .title-bar {
        background-color: $disiBluegreyMedium;
        min-height: 60px;

        p {
            color: white;
            font-weight: 500;

        }

        .stationNumber {
            font-size: 1.5rem;
        }

        .plannedTime {
            &::before {
                content: " ";
                display: inline-block;
                height: 25px;
                width: 25px;
                margin-right: 8px;
                margin-bottom: 3px;
                background-image: url('../assets/plannedTime_weiss.svg');
            }
        }
    }

    .detail_basket {
        position: relative;
    }

    .overAll {
        position: absolute;
        top: 0;
    }

    .tubes {
        position: relative;
        width: 165px;
        height: 165px;
    }

    .detail_basketContainer--top {
        position: absolute;
        top: 0;
        left: 0;
        height: 50%;
        width: 100%;
        padding: 25px 30px 7px 30px;
    }

    .detail_basketContainer--bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50%;
        width: 100%;
        padding: 7px 30px 25px 30px;
    }

    .v-progress-circular.finished {
        opacity: 0 !important;
    }

    .tube_info {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 14px;

        &.finished {
            background-color: $disiIrisblue;
            color: white;
        }

        &.noTest {
            background-color: transparent;
            border: 4px solid $disiBluegreyMedium;
            color: white;
        }
    }

    .disconnected, .disconnected p {
        color: $disiBluegreyMedium;
    }

    .temperature {
        &::after {
            content: " ";
            height: 52px;
            width: 35px;
            margin-top: -5px;
            margin-left: 8px;
            background-image: url('../assets/temperature_irisblue.svg');
        }

        &.disabled {
            color: $disiBluegreyMedium;

            &::after {
                background-image: url('../assets/temperature_bluegreyMedium.svg');
            }
        }
    }

    .heater-switch {
        width: 88px;

        .switchLabel {
            font-size: 0.85rem;
            color: $disiGrey;
            padding-bottom: 8px;
        }
    }

    .icon-temperature {
        height: 70px;
        margin-left: 10px;
        padding-bottom: 7px;
    }
}

.twoStations .StationSingleOverview {
    .basket {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -53%);
        flex: 100%;
        max-width: 100%;
        height: 273px;
        padding: 0;
        align-items: center;

        .detail_basket {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .basket_status, .tubes {
            width: 273px !important;
            height: 273px !important;
        }

        .detail_basketContainer--top {
            height: 50%;
            padding: 40px 45px 10px 45px;
        }

        .detail_basketContainer--bottom {
            height: 50%;
            padding: 10px 45px 40px 45px;
        }

        .tube_info {
            width: 48px;
            height: 48px;
            font-size: 20px;
        }
    }

    .infos {
        flex: 100%;
        max-width: 100%;
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .infos_bottom {
        justify-content: space-between !important;
    }
}

</style>